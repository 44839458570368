import React from 'react'
import module from './Title.module.scss'



export const Title = () => {
    return (
        <h3 className={module.title}>Вакансии</h3>
    )
}
