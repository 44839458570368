export const aboutUs = {
    name: 'Компания ООО “CАВ-строймонтаж”',
    head: ' – это многолетний союз профессионалов, которые выполняют услуги по ремонту и отделке под ключ квартир, новостроек и отделке коттеджей в Минске и Минском районе, ремонт офисов, магазинов, кафе, отделку любых коммерческих и производственных помещений.',
    body: `
Огромный опыт и наличие профессионального инструмента позволяют выполнять проекты любой сложности.
Ремонт жилых и нежилых помещений - ПОД КЛЮЧ, БЕЗ АВАНСОВ и В СЖАТЫЕ СРОКИ.
Мы постоянно тестируем появляющиеся на рынке отделочные материалы и осваиваем новые технологии, что позволяет нам выполнять услуги на уровне элитной отделки помещений, но по честным ценам. 
Ознакомьтесь с качеством оказываемых нами услуг по ремонту и отделке жилых и нежилых помещений в разделе НАШИ РАБОТЫ, объекты выполнены в Минске и Минской области.
На все выполненные работы компания ООО “CАВ-строймонтаж”  предоставляет гарантию от 2 до 5 лет.
А выезд на объект и составление предварительной сметы на услуги по ремонту и отделке выполним совершенно БЕСПЛАТНО.`,
    requisitesTitle:`
Наши реквизиты:`,
    requisites: `
Юридический/почтовый адрес:
ул. Привабная, 5-2Н, г. Минск
Тел/факс 317-52-75
Расчетный счет в формате
IBAN:
BY33ALFA30122326940010270000
Код банка в формате БИК: ALFABY2X
ЗАО «Альфа-Банк»
ул. Притыцкого,39 г. Минск
УНП 193048748 
e-mail: savstroi@bk.ru`
}